<template>
  <v-container class="cryptomation-container">
    <v-row dense>
      <v-col cols="12">
        <h1>Payment status</h1>
      </v-col>
      <v-col cols="12">
        <v-sheet v-if="dialogStatus === 0" class="pa-5 text-center">
          <div class="pb-5 text-center">
            <v-progress-circular
              size="100"
              indeterminate
              color="green"
            ></v-progress-circular>
          </div>

          <p class="headline text-center">Checking .... please wait</p>
        </v-sheet>

        <v-sheet v-if="dialogStatus === 1" class="pa-5 text-center">
          <v-icon color="green" size="100"> mdi-checkbox-marked-circle </v-icon>
          <p class="headline text-c enter" v-html="headerMessageText">!</p>
          <p class="title text-center" v-html="messageText"></p>
        </v-sheet>

        <v-sheet v-if="dialogStatus > 1" class="pa-5 text-center">
          <v-icon color="red" size="100"> mdi-close-circle-outline </v-icon>
          <p class="headline text-center" v-html="headerMessageText">!</p>
          <p class="title text-center" v-html="messageText"></p>
          <v-btn depressed color="primary" to="/tradingbot/"> back to trading bot</v-btn>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// transNo=107275&respCode=0&status=complete&orderNo=20220314080108717
export default {
  name: "ChillPayMerchantStatusPage",
  components: {},
  data: () => ({
    orderNo: "",
    transNo: "",
    respCode: "",
    status: "",
    dialogStatus: 0,
    headerMessageText: "",
    messageText: "",
  }),
  methods: {
    showDialogAction() {
      if (this.status === "complete") {
        this.dialogStatus = 1;
        this.headerMessageText = "Payment success";
      } else {
        this.dialogStatus = 2;
        this.headerMessageText = "Payment fail";
      }
    },
    redirectToRobotList() {
      this.$router.push({
        path: "/tradingbot/",
      });
    },
  },
  computed: {},
  watch: {},
  mounted() {
    this.orderNo = this.$route.query.orderNo;
    this.transNo = this.$route.query.transNo;
    this.respCode = this.$route.query.respCode;
    this.status = this.$route.query.status;
    // redirect
    if (
      this.orderNo === undefined ||
      this.orderNo.length === 0 ||
      this.transNo === undefined ||
      this.transNo.length === 0 ||
      this.respCode === undefined ||
      this.respCode.length === 0 ||
      this.status === undefined ||
      this.status.length === 0
    ) {
      this.redirectToRobotList();
    }

    this.showDialogAction();
  },
};
</script>

<style scoped>
</style>